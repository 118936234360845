import Title from './title';

const Roadmap = (props) => {

const huskiesonDisplay = ["310.png","420.png","69.png","109.png","logo.gif","123.png","115.png","1337.png","266.png"]

return (
    <>
    {/** Start of Intro */}
    <div class="flex flex-wrap pt-2">

        <div>
          <h1 class="text-6xl md:text-6xl xl:text-9xl font-bold">Roadmap</h1>

          <div class="pr-5 py-3">
            <div class="lg:text-base xl:text-lg pb-2 text">    
              <Title title="Built to last, powered by the community" />
          
          <p>Comming Soon </p>
                  
             </div>

          </div>
        </div>


      
    
 </div>

{/** End of Intro */}
</>
  );
};

export default Roadmap;
